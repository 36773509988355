<template>
  <div class="p-5">
    <p class="mb-4">{{ info }}</p>
    <input
      class="focus:outline-none focus:shadow-outline py-3 px-4 block appearance-none leading-normal w-full mt-3 shadow-sm"
      :class="{ 'border-red border-2 shake': error }"
      type="text"
      :value="cardTitle"
      @input="(event) => (cardTitle = event.target.value)"
      maxlength="40"
      @focus="error = false"
      @keyup.enter="addTitle()"
      ref="title"
      data-testid="title"
      placeholder="Kirjoita tähän kortin otsikko *"
    />
    <div class="relative h-18">
      <span v-if="error" class="text-12 absolute mt-1 top-0 left-0 text-veke-red">
        Pakollinen kenttä
      </span>
      <span class="text-12 text-grey-darker absolute mt-1 top-0 right-0"> {{ cardTitle.length }}/40 </span>
    </div>
    <button class="btn btn-veke mt-2 w-full" @click="addTitle()" :disabled="cardTitle.length === 0" data-testid="btn-title">
      Jatka
    </button>
  </div>
</template>

<script>
import giftcardSubComponent from '@/mixins/giftcardSubComponent'
import GtManager from '@/mixins/GtManager'
import { mapGetters } from 'vuex'
const stepNumber = 2 // used with google tag manager
export default {
  name: 'CardTitle',
  mixins: [giftcardSubComponent, GtManager],
  data() {
    return {
      error: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => this.$refs.title.focus(), 100)
    })
  },
  computed: {
    ...mapGetters(['theme']),
    cardTitle: {
      set: function(value) {
        this.$store.commit('SET_CARD_TITLE', value)
      },
      get: function() {
        return this.$store.getters['cardTitle']
      },
    },
  },
  methods: {
    addTitle() {
      if (this.cardTitle === '') {
        this.error = true
      }
      const gtmData = {
        from: stepNumber,
        to: stepNumber + 1,
        event: 'stepForward',
      }
      this.pushDataLayer(gtmData)
      this.$store.commit('MOVE_SECTION', 3)
    },
  },
}
</script>
