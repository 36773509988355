/* eslint-disable */
export default {
  methods: {
    pushDataLayer(gtmData) {
      if (process.env.NODE_ENV === 'production') {
        const direction = gtmData.from < gtmData.to ? 'stepForward' : 'stepBack'
        dataLayer.push({
          stepFrom: +gtmData.from,
          stepTo: +gtmData.to,
          selectedValue: gtmData.selectedValue !== undefined ? gtmData.selectedValue : '',
          event: gtmData.event
        })
      }
    },
  },
}
