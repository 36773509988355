<template>
  <div class="relative text-center z-1" :data-testid="theme.name" @click="selectTheme(theme.id)">
    <p class="text-veke-red m-0 absolute uppercase centered text-16 sm:text-20 font-bold sm:whitespace-no-wrap">
      {{ theme.name }}
    </p>
    <img class="shadow-sm hover:shadow-button cursor-pointer transition duration-200 ease-in-out" :class="{ 'shadow-button': selected(theme.id) }" :src="theme.image" alt="theme" />
    <span v-if="selected(theme.id)" class="absolute top-0 m-2 left-0 z-50">
      <i class="text-veke-rain fa-2x fas fa-check-circle"></i>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GtManager from '@/mixins/GtManager'
const stepNumber = 1 // used with google tag manager
/* eslint-disable */
export default {
  name: 'Theme',
  mixins: [GtManager],
  props: {
    theme: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      selectedThemeId: 'theme',
    }),
  },
  methods: {
    selectTheme(value) {
      this.$store.dispatch('selectTheme', value)
      const gtmData = {
        from: stepNumber,
        to: stepNumber + 1,
        selectedValue: this.selectedThemeId,
        event: 'stepForward',
      }
      this.pushDataLayer(gtmData)
    },
    selected(themeId) {
      return themeId === this.selectedThemeId
    },
  },
}
</script>

<style lang="scss" scoped>
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
