<template>
  <div class="giftcard">
    <div class="giftcard__header relative" :class="{ 'image-loaded': loaded }">
      <div class="absolute top-0 right-0 left-0 mx-auto w-25 my-10">
        <img src="../../assets/images/veke-logo.svg" alt="veke_logo" />
      </div>
      <img :src="cardImage" alt="header_image" @load="imageLoaded()" />
    </div>
    <h1 class="giftcard__title leading-none">
      {{ giftcardData.title }}
    </h1>
    <div class="giftcard__text">
      {{ giftcardData.text }}
    </div>
    <div class="giftcard__price">
      <p class="uppercase font-bold font-filson text-14">Tämä on {{ giftcardData.amount }}€ lahjakortti Veken sisustajakauppaan.</p>
      <div class="giftcard__price__container bg-veke-red px-5 py-2 my-5">
        <p class="font-filson text-44 font-bold text-white">{{ giftcardData.amount }}€</p>
      </div>
      <p class="uppercase font-bold font-filson">KOODI: {{ giftcardData.serial_number || giftcardData.code }}</p>
      <p class="italic mt-2">
        Lahjakortti käy maksuvälineenä verkkokaupassa ja myymälöissämme.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
/* eslint-disable */
export default {
  name: 'GiftcardPDF',
  props: {
    giftcardData: {
      type: Object,
    },
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters(['giftcard', 'themes']),
    cardImage() {
      return this.themes.filter((theme) => theme.id === this.giftcardData.theme)[0].card_image
    },
  },
  methods: {
    imageLoaded() {
      this.loaded = true
    },
  },
}
</script>

<style lang="scss" scoped>
.giftcard {
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  &__header {
    overflow: hidden;
    width: 100%;
    img {
      margin-top: -50px;
    }
  }
  &__title {
    color: #e73454;
    font-size: 3em;
    text-align: center;
    margin-top: 15px;
  }
  &__text {
    margin: 15px auto;
    line-height: 28px;
    font-size: 28px;
    font-family: 'filson-pro', Helvetica, sans-serif;
    max-width: 600px;
    height: 200px;
    text-align: center;
  }
  &__price {
    margin-top: 40px;
    background-color: #f7d0c5;
    padding: 30px 10px 30px 10px;
    text-align: center;
    &__container {
      width: 200px;
      margin: 0 auto;
    }
  }
}
</style>
