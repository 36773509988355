import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import ThankYou from '../views/ThankYou.vue'
import DownloadGiftcard from '@/views/DownloadGiftcard'
import PdfPrintView from '@/views/PdfPrintView'
import Checkout from '@/views/Checkout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/kiitos',
    name: 'ThankYou',
    component: ThankYou,
  },
  {
    path: '/lahjakortti/:serial_number/:token',
    name: 'download-giftcard',
    component: DownloadGiftcard,
    props: true,
  },
  {
    path: '/pdf/:serial_number/:token',
    name: 'GiftcardPDF',
    component: PdfPrintView,
  },
  {
    path: '/kassa',
    name: 'Checkout',
    component: Checkout,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
