<template>
  <div class="container pb-10">
    <div v-if="!loaded" class="mt-10 justify-center flex">
      <div data-testid="loading-spinner">
        <loading-spinner />
      </div>
    </div>
    <div v-else class="row">
      <div data-testid="content-container" class="col-12 sm:col-8 lg:col-6 xl:col-4 sm:offset-2 lg:offset-3 xl:offset-4 my-8">
        <p v-html="parsedInfoText()"></p>
      </div>
      <div class="col-12 sm:col-8 lg:col-6 xl:col-4 sm:offset-2 lg:offset-3 xl:offset-4">
        <accordion />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import InfoText from '@/components/InfoText'
import Accordion from '@/components/accordion/Accordion'
import { mapGetters } from 'vuex'
import moment from 'moment'
import themes from '@/helpers/themes'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

/* eslint-disable */
export default {
  name: 'Home',
  components: {
    LoadingSpinner,
    InfoText,
    Accordion
  },
  async mounted() {
    window.addEventListener('message', this.receiveMessage)
    this.checkOldCardDetailsData()
    this.loaded = true
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage)
  },
  data() {
    return {
      richText: null,
      loaded: false
    }
  },
  computed: {
    ...mapGetters(['infoText', 'cardDetails'])
  },
  methods: {
    receiveMessage(event) {
      if (!event.data || event.data === 'undefined') return;
      let data =
        typeof event.data === 'string' ? JSON.parse(event.data) : event.data
      if (
        process.env.NODE_ENV === 'production' &&
        data.args !== undefined &&
        data.args.length > 0 &&
        data.args[0].eventName === 'confirmation_displayed'
      ) {
        dataLayer.push({
          event: 'lahjakortti-purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: data.args[0].eventPayload.order_id,
                affiliation: 'Lahjakorttikauppa',
                revenue: this.cardDetails.amount,
                tax: '0',
                shipping: '0',
              },
              products: [
                {
                  name: 'Lahjakortti',
                  id: 'VLK-WEB',
                  price: this.cardDetails.amount,
                  variant: this.theme(this.cardDetails.theme),
                  quantity: 1,
                },
              ],
            },
          },
        })
      }
    },
    theme(id) {
      const index = id - 1
      return themes[index].name
    },
    checkOldCardDetailsData() {
      // remove card details if in localstorage and added over 2h ago
      if (
        this.cardDetails.added &&
        moment(this.cardDetails.added).format() <
          moment()
            .subtract(2, 'h')
            .format()
      ) {
        localStorage.clear()
        this.$store.commit('RESET_CARD_STATE')
      }
    },
    parsedInfoText() {
      return documentToHtmlString(this.infoText)
    },
  },
}
</script>

<style>
body {
  background-color: #F6F4EF;
}
</style>
