<template>
  <div>
    <p v-if="infoRow.link" class="text-xs leading-tight text-white">
      <a :href="infoRow.link">
        {{ infoRow.value }}
      </a>
    </p>
    <p v-else class="text-xs text-white">
      {{ infoRow.value }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FooterSectionRow',
  props: {
    infoRow: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
