<template>
  <div class="pb-5 px-3">
    <div class="relative">
      <img class="relative" :src="cardImage" alt="preview_image" />
      <div class="absolute top-0 right-0 left-0 mx-auto w-25 bg-white pt-2 pb-5 px-3 sm:px-5">
        <img src="../../assets/images/Veke_Logo_RGB.jpg" alt="veke_logo" />
      </div>
    </div>
    <div class="px-10">
      <h4 class="text-center my-3" data-testid="preview-title">
        {{ card_details.title || 'Otsikko puuttuu' }}
      </h4>
      <p class="mb-5 text-veke-black" v-if="card_details.text" data-testid="preview-text" v-html="card_details.text" style="white-space: pre-line;" />
      <div v-else class="bg-white w-full card-text mb-5" />
      <div class="bg-white w-full p-2" data-testid="preview">
        <p class="text-veke-grey-dark font-filson">
          Lahjakortin arvo
        </p>
        <p class="text-32 font-bold font-filson text-veke-black">{{ card_details.amount }}€</p>
      </div>
    </div>
    <div v-if="email" class="mt-5">
      <p class="text-veke-black">Lahjakortti toimitetaan osoitteeseen: {{ email }}</p>
    </div>
    <div class="mt-7 flex justify-center">
      <router-link to="/kassa" class="btn btn-veke">
        Siirry kassalle
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GtManager from '@/mixins/GtManager'
export default {
  name: 'PreviewCard',
  mixins: [GtManager],
  mounted() {
    this.$store.commit('SET_PAYMENT_BUTTON_VISIBLE_STATUS', true)
  },
  beforeUnmount() {
    // remove button iframe, will not work in same iframe on production
    let iframes = document.querySelectorAll('iframe')
    for (let i = 0; i < iframes.length; i++) {
      if (iframes[i].name === 'klarna-instant-shopping-button-button-123abc456') {
        iframes[i].parentNode.removeChild(iframes[i])
      }
    }
  },
  computed: {
    ...mapGetters({
      email: 'sendToEmail',
      theme_id: 'theme',
      card_details: 'cardDetails',
      payment_button_visible: 'paymentButtonVisible',
      themes: 'themes',
    }),
    cardImage() {
      return this.themes.filter((theme) => theme.id === this.theme_id)[0].card_image
    },
  },
}
</script>

<style lang="scss" scoped>
.card-text {
  height: 200px;
}
</style>
