<template>
  <div class="row h-full flex items-center">
    <div class="col-8 sm:col-4 offset-2 sm:offset-4 text-center">
      <h3>Kiitos lahjakortin tilauksesta</h3>
      <p class="mt-2 text-veke-black" v-html="info"></p>
      <button class="btn btn-veke mt-5" @click="goToVekeShop()">
        Siirry veke.fi sivustolle
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
export default {
  name: 'ThankYou',
  beforeCreate() {
    // prevent accessing view if session has not been activated (step 6 in accordion)
    if (!this.$store.getters['sessionActive']) this.$router.push({ name: 'Home' })
  },
  mounted() {
    sessionStorage.clear()
    localStorage.clear()
    this.$store.commit('RESET_CARD_STATE')
  },
  computed: {
    ...mapGetters(['thankYouPageInfo']),
    info() {
      return documentToHtmlString(this.thankYouPageInfo)
    },
  },
  methods: {
    goToVekeShop() {
      window.location = 'https://veke.fi'
    },
  },
}
</script>
