<template>
  <div class="p-5">
    <p class="mb-4">{{ info }}</p>
    <div v-if="sendToEmail || print" class="flex flex-col mt-2">
      <input
        class="focus:outline-none focus:shadow-outline py-3 px-4 block appearance-none leading-normal w-full shadow-sm"
        :class="{ 'border-red border-2 shake': error }"
        @focus="error = false"
        v-model.trim="email"
        type="email"
        name="email"
        placeholder="Anna sähköpostiosoite"
        data-testid="sendToEmail"
      />
      <label v-if="print" class="text-gray text-left my-3 h-full flex items-center focus:outline-none focus:shadow-outline">
        <input class="mr-2 leading-tight h-20 w-20" v-model="addToMailingList" type="checkbox" />
        <p class="text-12">
          Liityn Veken uutiskirjetilaajaksi
        </p>
      </label>
      <button class="btn btn-veke my-2" @click="goToPreview()" data-testid="applyEmail">
        Jatka esikatseluun
      </button>
    </div>
    <div v-else class="flex flex-col mt-2">
      <button class="btn btn-veke mb-2" @click="setDeliveryMethod(1)" data-testid="selfPrint">
        Tulostan kortin itse
      </button>
      <button class="btn btn-veke mb-2" @click="setDeliveryMethod(2)">
        Toimitus sähköpostiosoitteeseen
      </button>
    </div>
  </div>
</template>

<script>
import giftcardSubComponent from '@/mixins/giftcardSubComponent'
import GtManager from '@/mixins/GtManager'
import { useVuelidate } from '@vuelidate/core'
import { mapGetters } from 'vuex'
import { required, email } from '@vuelidate/validators'
const stepNumber = 5 // used with google tag manager
/* eslint-disable */
export default {
  name: 'Delivery',
  mixins: [giftcardSubComponent, GtManager],
  data() {
    return {
      print: false,
      sendToEmail: false,
      error: false,
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validationConfig: {
    $lazy: true,
  },
  validations () {
    return {
      email: {
        required,
        email
      }
    }
  },
  computed: {
    ...mapGetters([
      'cardDetails',
      'amount',
      'buttonKey',
      'klarnaLoaded',
      'paymentButtonVisible',
      'theme',
      'deliveryMethod'
    ]),
    email: {
      set: function(payload) {
        this.$store.commit('SET_TO_EMAIL', payload)
      },
      get: function() {
        return this.$store.getters['sendToEmail']
      },
    },
    addToMailingList: {
      set: function(payload) {
        this.$store.dispatch('addToMailingList', payload)
      },
      get: function() {
        return this.$store.getters['addToMailingList']
      },
    },
  },
  methods: {
    setDeliveryMethod(method) {
      this.print = method === 1;
      this.sendToEmail = true
      this.$store.dispatch('setDeliveryMethod', method)
    },
    goToPreview() {
      this.v$.$touch()
      if (this.v$.$invalid) {
          this.error = true
          setTimeout(() => { this.error = false }, 2000)
      } else {
        const gtmData = {
          from: stepNumber,
          to: stepNumber + 1,
          selectedValue: this.deliveryMethod,
          event: 'stepForward',
        }
        this.pushDataLayer(gtmData)
        this.$store.commit('MOVE_SECTION', 6)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
  content: '';
  appearance: none;
  border: 0.1rem solid black;
  display: inline-block;
  cursor: pointer;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
input[type='checkbox']:checked {
  background-color: #e73454;
}
input[type='checkbox']:checked:after {
  display: inline-block;
}
input[type='checkbox']:after {
  content: '\2713';
  font-size: 12px;
  color: white;
  display: none;
}
</style>
