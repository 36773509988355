<script setup>
import axios from 'axios'
import { onMounted, ref, defineExpose, computed, defineEmits } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const parsedPaymentMethods = []
const paymentProviders = ref([])
const showInfo = ref('')
const loading = ref(true)
const selectedPaymentProvider = ref(null)
const selectedPaymentMethod = ref(null)
const showProviderInfo = (provider) => {
  return showInfo.value === provider
}
const show = (data) => {
  if (data === 'KLARNA') {
    selectedPaymentProvider.value = 'klarna'
    selectedPaymentMethod.value = {
      code: 'KLARNA',
      imageUrl: '',
    }
  } else if (data === 'Korttimaksu') {
    selectedPaymentProvider.value = 'svea'
    selectedPaymentMethod.value = {
      code: 'FI50',
      imageUrl: '',
    }
  } else {
    selectedPaymentProvider.value = 'svea'
  }
  showInfo.value = data
}

const selectMethod = (method) => {
  selectedPaymentMethod.value = method
}

const cardAmount = computed(() => store.getters.cardAmount)

const selected = (method) => {
  return selectedPaymentMethod.value?.code === method.code
}

const emit = defineEmits('loaded')

defineExpose({
  selectedPaymentMethod,
  selectedPaymentProvider,
  loading,
})

onMounted(() => {
  axios
    .get(process.env.VUE_APP_FUNCTIONS_DOMAIN + '/veke3000-paymentGateway-getPaymentMethodsForDomain?total=' + cardAmount.value + '&domain=lahjakortti')
    .then((response) => {
      paymentProviders.value = response.data
      response.data.forEach((provider) => {
        if (provider.paymentGroups) {
          provider.paymentGroups.forEach((paymentGroup) => {
            const data = {
              title: paymentGroup.title,
              imageUrl: paymentGroup.imageUrl,
              availablePaymentMethods: paymentGroup.availablePaymentMethods,
            }
            if (paymentGroup.title !== 'KLARNA') {
              parsedPaymentMethods.push(data)
            }
          })
        }
      })
      loading.value = false
    })
    .finally(() => emit('loaded'))
})
</script>

<template>
  <section>
    <div v-if="!loading">
      <div v-for="paymentProvider in parsedPaymentMethods" :key="paymentProvider.title" class="my-2">
        <div class="bg-white rounded border border-veke-grey p-4 cursor-pointer hover:shadow" @click="show(paymentProvider.title)">
          <div class="flex justify-between items-center">
            <p class="m-0">{{ paymentProvider.title }}</p>
            <img :src="paymentProvider.imageUrl" alt="payment-image" class="md:max-w-1/3" style="max-height: 40px;" />
          </div>
          <div v-if="showProviderInfo(paymentProvider.title)" class="mt-2">
            <div v-if="paymentProvider.availablePaymentMethods.length > 1" class="uppercase">
              Valitse:
            </div>
            <div v-if="paymentProvider.availablePaymentMethods.length > 1" class="flex flex-wrap">
              <div v-for="pp in paymentProvider.availablePaymentMethods" :key="pp" class="method-wrapper">
                <div class="border m-1 p-2 flex items-center justify-center" :class="selected(pp) ? 'border-veke-pink-new' : 'border-veke-grey'" @click="selectMethod(pp)">
                  <img v-if="pp.imageUrl" :src="pp.imageUrl" alt="logo" style="max-width: 100px; max-height: 40px" />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="border border-veke-pink-new border-l-8 p-2 rounded">
                <p>{{ paymentProvider.availablePaymentMethods[0].code === 'FI50' ? 'Korttimaksu (Visa, Visa Electron, MasterCard)' : paymentProvider.availablePaymentMethods[0].code }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.method-wrapper {
  width: 20%;
  height: 70px;
  @media (max-width: 680px) {
    width: 50%;
  }
}
</style>
