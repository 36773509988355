<template>
  <div class="flex items-center">
    <div class="mx-2">
      <div class="icon-container">
        <i class="pad1 fab fa-instagram text-white"></i>
      </div>
    </div>
    <div class="mx-2">
      <div class="icon-container">
        <i class="pad1 fab fa-facebook-f text-white"></i>
      </div>
    </div>
    <div class="mx-2">
      <div class="icon-container">
        <i class="pad1 fab fa-pinterest-p text-white"></i>
      </div>
    </div>
    <div class="mx-2">
      <div class="icon-container">
        <i class="pad1 fab fa-youtube text-white"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialMediaIcons',
}
</script>

<style lang="scss" scoped>
.icon-container {
  @apply bg-veke-red;
  @apply w-32;
  @apply h-32;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply rounded-full;
}
.pad1 {
  padding-left: 1px;
}
</style>
