<template>
  <div class="p-5">
    <p class="mb-4">{{ info }}</p>
    <div class="flex flex-col mt-2">
      <div class="relative" v-for="amount in amounts" :key="amount">
        <button class="btn btn-veke mt-2 w-full focus:outline-none" :class="{ 'bg-veke-turqoise-dark': selected(amount) }" @click="selectAmount(amount)">{{ amount }} €</button>
        <span v-if="selected(amount)" class="absolute top-0 left-0 ml-1">
          <i class="text-white fa-2x fas fa-check-circle"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import giftcardSubComponent from '@/mixins/giftcardSubComponent'
import GtManager from '@/mixins/GtManager'
const stepNumber = 4 // used with google tag manager
/* eslint-disable */
export default {
  name: 'CardAmount',
  mixins: [giftcardSubComponent, GtManager],
  computed: {
    ...mapGetters([
      'amounts',
      'cardAmount',
      'klarnaLoaded',
      'paymentButtonVisible',
      'cardDetails',
      'buttonKey',
      'theme'
    ]),
  },
  methods: {
    selectAmount(amount) {
      this.$store.dispatch('setAmount', +amount)
      // if (this.klarnaLoaded && !this.paymentButtonVisible) {
      //   const klarnaData = {
      //     amount: +amount,
      //     cardDetails: this.cardDetails,
      //   }
      //   window.klarnaAsyncCallback = update(klarnaData)
      // } else {
      //   const klarnaData = {
      //     amount: +amount,
      //     cardDetails: this.cardDetails,
      //     shippingMethod: 0,
      //     buttonKey: this.buttonKey,
      //   }
      //   window.klarnaAsyncCallback = load(klarnaData)
      //   this.$store.commit('SET_PAYMENT_BUTTON_VISIBLE_STATUS', false)
      //   this.$store.commit('SET_KLARNA_LOADED')
      // }
      const gtmData = {
        from: stepNumber,
        to: stepNumber + 1,
        selectedValue: amount,
        event: 'stepForward'
      }
      this.pushDataLayer(gtmData)
    },
    selected(amount) {
      return amount === this.cardAmount
    },
  },
}
</script>
