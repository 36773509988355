<template>
  <div class="p-5">
    <p class="mb-4">{{ info }}</p>
    <textarea
      class="focus:outline-none focus:shadow-outline py-3 px-4 block appearance-none leading-normal w-full mt-3 shadow-sm"
      name="card-text"
      id="text-area"
      cols="30"
      rows="7"
      ref="text"
      @keydown.enter.prevent="addText()"
      data-testid="text"
      :value="cardText"
      @input="(event) => (cardText = event.target.value)"
    />
    <p class="text-12 text-grey-darker text-right w-full mt-1">{{ cardText.length }}/250</p>
    <button class="btn btn-veke mt-2 w-full" @click="addText()" :disabled="cardText.length === 0" data-testid="btn-test">
      Jatka
    </button>
  </div>
</template>

<script>
import giftcardSubComponent from '@/mixins/giftcardSubComponent'
import GtManager from '@/mixins/GtManager'
import { mapGetters } from 'vuex'
const stepNumber = 3 // used with google tag manager
export default {
  name: 'CardText',
  mixins: [giftcardSubComponent, GtManager],
  mounted() {
    this.$refs.text.focus()
  },
  computed: {
    ...mapGetters(['theme']),
    cardText: {
      set: function(text) {
        this.$store.dispatch('setCardText', text)
      },
      get: function() {
        return this.$store.getters['cardText']
      },
    },
  },
  methods: {
    addText() {
      const gtmData = {
        from: stepNumber,
        to: stepNumber + 1,
        event: 'stepForward',
      }
      this.pushDataLayer(gtmData)
      this.$store.commit('MOVE_SECTION', 4)
    },
  },
}
</script>
