<template>
  <div>
    <div class="loader" />
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
}
</script>

<style lang="scss" scoped>
.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #e73454;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
