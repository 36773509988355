<template>
  <footer class="bg-veke-gunmetal py-10 mt-20 px-5 z-10">
    <div class="footer mx-auto">
      <div class="flex flex-col sm:flex-row justify-around">
        <footer-section v-for="(section, i) in sections" class="flex flex-col sm:text-center" :section-data="section" :key="i" />
      </div>
      <div class="flex flex-col sm:flex-row justify-around items-center mt-10">
        <img src="https://cdn.klarna.com/1.0/shared/image/generic/badge/fi_fi/checkout/long-white.png?width=440" alt="payment-logos" />
        <div class="mt-5 sm:mt-0 sm:mx-0 mx-auto">
          <social-media-icons />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import SocialMediaIcons from '@/components/footer/sub/SocialMediaIcons'
import FooterSection from '@/components/footer/sub/FooterSection'

/* eslint-disable */
export default {
  name: 'VekeFooter',
  components: { SocialMediaIcons, FooterSection },
  data() {
    return {
      section: false,
      sections: [
        {
          title: 'Asiakaspalvelu',
          info_rows: [
            { value: '09 425 50420', link: null },
            { value: 'Ma-Pe 9-16', link: null },
          ],
        },
        {
          title: 'Myymälät',
          info_rows: [
            { value: 'Oulu', link: 'https://www.veke.fi/myymalat/oulu/' },
            { value: 'Rovaniemi', link: 'https://www.veke.fi/myymalat/rovaniemi/' },
            { value: 'Tampere', link: 'https://www.veke.fi/myymalat/tampere/' },
            { value: 'Vantaa', link: 'https://www.veke.fi/myymalat/vantaa/' },
            { value: 'Raisio', link: 'https://www.veke.fi/myymalat/raisio/' },
            { value: 'Noutopisteet', link: 'https://www.veke.fi/noutopistet/' },
          ],
        },
        {
          title: 'Tilaus- ja toimitusehdot',
          info_rows: [
            { value: 'Verkkokaupan ehdot', link: 'https://www.veke.fi/asiakaspalvelu/verkkokaupan-ehdot' },
            { value: 'Tietosuoja ja rekisteriseloste', link: 'https://www.veke.fi/asiakaspalvelu/tietosuoja-rekisteriseloste' },
          ],
        },
        {
          title: 'Yrityksestämme',
          info_rows: [
            { value: 'Veken tarina', link: 'https://www.veke.fi/yrityksestamme/veken-tarina/' },
            { value: 'Arvot & lupaus', link: 'https://www.veke.fi/yrityksestamme/arvot-lupaus/' },
            { value: 'Meistä', link: 'https://www.veke.fi/yrityksestamme/henkilosto/' },
            { value: 'Työpaikat', link: 'https://www.veke.fi/yrityksestamme/tyopaikat/' },
            { value: 'Asiakaskokemuksia', link: 'https://www.veke.fi/yrityksestamme/asiakaskokemuksia/' },
          ],
        },
      ],
    }
  },
  methods: {
    showSection() {
      this.section = true
    },
  },
}
</script>

<style lang="scss" scoped>
footer {
  min-height: 350px;
  -webkit-box-shadow: 0 -5px 5px 0 rgba(202, 213, 218, 0.16),
    0 -10px 20px 0 rgba(229, 238, 240, 0.75);
  box-shadow: 0 -5px 5px 0 rgba(202, 213, 218, 0.16),
    0 -10px 20px 0 rgba(229, 238, 240, 0.75);
}
.footer {
  max-width: 1200px;
}
</style>
