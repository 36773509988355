<template>
  <div>
    <accordion-item v-for="(item, i) in accordions" :id="item.id" :item="item" :key="i" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AccordionItem from '@/components/accordion/sub/AccordionItem'
export default {
  name: 'Accordion',
  components: { AccordionItem },
  computed: {
    ...mapGetters({
      accordions: 'accordions',
    }),
  },
}
</script>
