<template>
  <div class="flex flex-col items-center align-center h-full w-full">
    <h4 class="font-filson font-bold text-center w-350">
      Lataa lahjakorttisi alla olevasta linkistä
    </h4>
    <button :disabled="loading" class="btn btn-veke mt-3 w-350 flex justify-center" @click="getPdf()">
      <span v-if="loading">
        <loading-spinner></loading-spinner>
      </span>
      <p v-else>
        Lataa lahjakortti
      </p>
    </button>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'
/* eslint-disable */
export default {
  name: 'DownloadGiftcard',
  props: {
    serial_number: {
      type: String,
    },
    token: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  components: { LoadingSpinner },
  created() {
    this.checkThatGivenDataIsValid()
  },
  methods: {
    checkThatGivenDataIsValid() {
      this.$store.dispatch('getGiftcardData', { serial_number: this.serial_number, token: this.token })
    },
    getPdf() {
      this.loading = true
      window.location = `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/lahjakortti-print?url=${process.env.VUE_APP_DOMAIN}/pdf/${this.serial_number}/${this.token}`
      setTimeout(() => this.loading = false, 10000)
    },
  },
}
</script>
