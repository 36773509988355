import Vuex from 'vuex'
import contentful from '@/plugins/contentful/client.js'
import moment from 'moment'
import axios from 'axios'
import { db } from '../../firebase'

const card_details_init = {
  title: '',
  text: '',
  amount: null,
  delivery: null,
  added: null,
  sendToEmail: null,
  addToMailingList: false,
  theme: null,
}
/* eslint-disable */
export default Vuex.createStore({
  state: {
    sessionActive: sessionStorage.getItem('sessioActive') || false,
    accordions: [
      {
        id: 1,
        active: true,
        title: '1. Valitse teema',
        component: 'Themes',
        info: '',
      },
      {
        id: 2,
        active: false,
        title: '2. Kortin otsikko',
        component: 'CardTitle',
        info: '',
      },
      {
        id: 3,
        active: false,
        title: `3. Kortin teksti`,
        component: 'CardText',
        info: '',
      },
      {
        id: 4,
        active: false,
        title: `4. Valitse summa`,
        component: 'Amount',
        info: '',
      },
      {
        id: 5,
        active: false,
        title: `5. Toimitustapa`,
        component: 'Delivery',
        info: '',
      },
      {
        id: 6,
        active: false,
        title: `6. Esikatselu`,
        component: 'PreviewCard',
        info: '',
      },
    ],
    themes: [],
    section: 1,
    info_text: '',
    amounts: [],
    amount: null,
    card_title: '',
    card_text: '',
    card_amount: null,
    delivery_method: null,
    loaded: true,
    orderSuccess: false,
    thankYouPageTitle: '',
    thankYouPageInfo: '',
    card_details: localStorage.getItem('cardDetails') ? JSON.parse(localStorage.getItem('cardDetails')) : card_details_init,
    klarnaLoaded: false,
    paymentButtonVisible: false,
    giftcardPdfData: null,
    buttonKey: null,
  },
  actions: {
    initContentfulData({ dispatch }) {
      dispatch('initContentfulThemes')
      dispatch('initContentfulContents')
    },
    initContentfulContents({ commit }) {
      contentful
        .getEntries({ content_type: 'giftshopData' })
        .then((response) => {
          commit('SET_INFO_TEXT', response.items[0].fields.pageInfo)
          commit('SET_ACCORDION_HELPERS', response.items[0].fields)
          commit('SET_AMOUNTS', response.items[0].fields.amounts)
          commit('SET_THANK_YOU_PAGE_TITLE', response.items[0].fields.thankYouPageTitle)
          commit('SET_THANK_YOU_PAGE_INFO', response.items[0].fields.thankYouPageInfo)
          commit('LOADED')
        })
        .catch((err) => {
          console.error(err)
          throw new Error(err)
        })
    },
    initContentfulThemes({ commit }) {
      contentful
        .getEntries({ content_type: 'giftshopTheme' })
        .then((response) => {
          response.items.forEach((item, i) => {
            let themeData = {
              id: +item.fields.themeId,
              active: item.fields.active,
              name: item.fields.themeName,
              image: 'https:' + item.fields.themeImage.fields.file.url,
              card_image: 'https:' + item.fields.cardImage.fields.file.url
            }
              commit('ADD_THEME', themeData)
          })
        })
        .catch((err) => {
          console.error(err)
          throw new Error(err)
        })
    },
    async initPaymentButtonKey({ commit }) {
      db.collection('lahjakortti-buttons')
        .get()
        .then(querySnapshot => {
          commit('SET_PAYMENT_BUTTON_KEY', querySnapshot.docs[0].data().button_key)
        })
    },
    selectTheme({ commit }, payload) {
      commit('SELECT_THEME', payload)
      commit('MOVE_SECTION', 2)
    },
    setCardText({ commit }, payload) {
      commit('SET_CARD_TEXT', payload)
    },
    setCardTitle({ commit }, payload) {
      commit('SET_CARD_TITLE', payload)
    },
    setAmount({ commit }, payload) {
      commit('SET_AMOUNT', payload)
      commit('MOVE_SECTION', 5)
    },
    setDeliveryMethod({ commit }, payload) {
      commit('SET_DELIVERY_METHOD', payload)
    },
    addToMailingList({ commit }, payload) {
      commit('SET_ADD_TO_MAILING_LIST', payload)
    },
    getGiftcardData({ commit }, payload) {
      return axios
        .get(process.env.VUE_APP_FIREBASE_FUNCTIONS_URL + '/lahjakortti-getGiftcardData', {
          params: { serial_number: payload.serial_number, token: payload.token },
          headers: { accept: 'application/json' },
        })
        .then((response) => {
          console.log(response.data)
          if (response.status === 200) {
            commit('SET_GIFTCARD_DATA', response.data)
          }
          return true
        })
        .catch((err) => {
          throw new Error(err)
        })
    },
  },
  mutations: {
    SET_PAYMENT_BUTTON_KEY(state, payload) {
      state.buttonKey = payload
    },
    SET_INFO_TEXT(state, payload) {
      state.info_text = payload
    },
    ADD_THEME(state, payload) {
      state.themes.push(payload)
    },
    SELECT_THEME(state, payload) {
      state.card_details.theme = payload
      addCardDetailsLocalStorage(state.card_details)
    },
    SET_CARD_TITLE(state, payload) {
      state.card_details.title = payload
      addCardDetailsLocalStorage(state.card_details)
    },
    SET_CARD_TEXT(state, payload) {
      state.card_details.text = payload
      addCardDetailsLocalStorage(state.card_details)
    },
    SET_AMOUNT(state, payload) {
      state.card_details.amount = +payload
      state.amount = +payload
      addCardDetailsLocalStorage(state.card_details)
    },
    SET_DELIVERY_METHOD(state, payload) {
      // if selected different method, reset email
      if (state.card_details.delivery !== payload) {
        state.sendToEmail = ''
      }
      state.card_details.delivery = payload
    },
    SET_AMOUNTS(state, payload) {
      state.amounts = payload
    },
    MOVE_SECTION(state, payload) {
      // if going to preview, activate sessio to enable acces to thank you page
      if (payload === 6) {
        state.sessio_active = true
        sessionStorage.setItem('sessioActive', true)
      }
      state.accordions.forEach((theme) => {
        if (theme.id === payload) {
          theme.active = true
        } else {
          theme.active = false
        }
      })
      state.section = payload
    },
    LOADED(state) {
      state.loaded = true
    },
    SET_ACCORDION_HELPERS(state, payload) {
      const accordionInfos = Object.values(payload)
      // contentful data object accordions starting from index 2 !!TODO maybe this can be done without hardcoded!!
      let index = 2
      state.accordions.forEach((accordion) => {
        accordion.info = accordionInfos[index]
        index++
      })
    },
    SET_TO_EMAIL(state, payload) {
      state.card_details.sendToEmail = payload
      addCardDetailsLocalStorage(state.card_details)
    },
    SET_THANK_YOU_PAGE_TITLE(state, payload) {
      state.thankYouPageTitle = payload
    },
    SET_THANK_YOU_PAGE_INFO(state, payload) {
      state.thankYouPageInfo = payload
    },
    SET_ADD_TO_MAILING_LIST(state, payload) {
      state.card_details.addToMailingList = payload
      addCardDetailsLocalStorage(state.card_details)
    },
    RESET_CARD_STATE(state) {
      // init with empty values
      state.card_details = Object.assign({}, card_details_init)
      // remove theme
      state.theme = null
      // reset session, prevent access straight to thank you page
      state.sessioActive = false
      // clear local storage
      localStorage.clear()
    },
    SET_KLARNA_LOADED(state) {
      state.klarnaLoaded = true
    },
    SET_PAYMENT_BUTTON_VISIBLE_STATUS(state, payload) {
      console.log('set payment button status: ', payload)
      state.paymentButtonVisible = payload
    },
    SET_GIFTCARD_DATA(state, payload) {
      state.giftcardPdfData = payload
    },
  },
  getters: {
    infoText: (state) => state.info_text,
    cardTitle: (state) => state.card_details.title,
    cardText: (state) => state.card_details.text,
    cardAmount: (state) => state.card_details.amount,
    amounts: (state) => state.amounts,
    amount: (state) => state.amount,
    deliveryMethod: (state) => state.card_details.delivery,
    accordions: (state) => state.accordions,
    loaded: (state) => state.loaded,
    themes: (state) => state.themes,
    activeThemes: (state) => state.themes.filter(theme => theme.active),
    theme: (state) => state.card_details.theme,
    sendToEmail: (state) => state.card_details.sendToEmail,
    section: (state) => state.section,
    orderSuccess: (state) => state.orderSuccess,
    thankYouPageInfo: (state) => state.thankYouPageInfo,
    cardDetails: (state) => state.card_details,
    klarnaLoaded: (state) => state.klarnaLoaded,
    paymentButtonVisible: (state) => state.paymentButtonVisible,
    addToMailingList: (state) => state.card_details.addToMailingList,
    giftcardData: (state) => state.giftcardPdfData,
    buttonKey: (state) => state.buttonKey,
    sessionActive: (state) => state.sessionActive,
  },
  modules: {},
});

// add card details data to localstorage, include date to remove it on home component mount cycle if old
function addCardDetailsLocalStorage(payload) {
  payload.added = moment().format()
  localStorage.setItem('cardDetails', JSON.stringify(payload))
}
