<template>
  <div>
    <span class="flex justify-between items-center cursor-pointer" @click="showInfoRows = !showInfoRows">
      <p class="uppercase mb-2 text-white">{{ sectionData.title }}</p>
      <i v-if="showInfoRows" class="fas fa-minus sm:hidden text-xs"></i>
      <i v-else class="fas fa-plus sm:hidden text-xs"></i>
    </span>
    <span class="sm:text-center sm:flex sm:flex-col mb-2 sm:mb-0" :class="{ hidden: !showInfoRows }">
      <footer-section-info-row v-for="(infoRow, i) in sectionData.info_rows" :info-row="infoRow" :key="i" />
    </span>
  </div>
</template>

<script>
import FooterSectionInfoRow from '@/components/footer/sub/FooterSectionInfoRow'

export default {
  name: 'FooterSection',
  components: { FooterSectionInfoRow },
  data() {
    return {
      showInfoRows: false,
    }
  },
  props: {
    sectionData: {
      type: Object,
      default: null,
    },
  },
}
</script>
