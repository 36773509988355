<template>
  <div v-if="dataLoaded">
    <giftcard-p-d-f :giftcard-data="giftcardData" />
  </div>
</template>

<script>
/* eslint-disable */
import GiftcardPDF from '@/components/PDF/GiftcardPDF'
import { mapGetters } from 'vuex'
export default {
  name: 'PdfPrintView',
  components: {
    GiftcardPDF,
  },
  data() {
    return {
      dataLoaded: false,
    }
  },
  mounted() {
    this.$store.dispatch('getGiftcardData', { serial_number: this.$route.params.serial_number, token: this.$route.params.token })
    .then(() =>  this.dataLoaded = true)
  },
  computed: {
    ...mapGetters(['giftcardData']),
  },
}
</script>
