<template>
  <div class="accordion-item" :class="{ 'is-active': item.active, 'bg-veke-cotton-600': item.active }">
    <dt class="accordion-item-title">
      <button @click="toggle(item.id)" class="accordion-item-trigger pointer focus:outline-none">
        <h4 class="accordion-item-title-text flex items-center" :class="{ 'text-veke-grey-dark': accordionIsChecked(item) }">
          {{ item.title }}
          <i v-if="accordionIsChecked(item)" class="ml-2 text-14 fas fa-edit" />
        </h4>
        <span class="accordion-item-trigger-icon"></span>
      </button>
    </dt>
    <transition name="accordion-item" @enter="startTransition" @after-enter="endTransition" @before-leave="startTransition" @after-leave="endTransition">
      <dd v-if="item.active" class="accordion-item-details">
        <div class="accordion-item-details-inner bg-veke-cotton-600">
          <component :is="item.component" :info="item.info" />
        </div>
      </dd>
    </transition>
  </div>
</template>

<script>
import Themes from '@/components/giftcard/Themes'
import CardTitle from '@/components/giftcard/CardTitle'
import CardText from '@/components/giftcard/CardText'
import Amount from '@/components/giftcard/Amount'
import Delivery from '@/components/giftcard/Delivery'
import PreviewCard from '@/components/giftcard/PreviewCard'
import GtManager from '@/mixins/GtManager'
/* eslint-disable */
export default {
  name: 'AccordionItem',
  props: {
    item: {
      type: Object
    }
  },
  mixins: [GtManager],
  components: {
    Themes,
    CardTitle,
    CardText,
    Amount,
    Delivery,
    PreviewCard,
  },
  methods: {
    toggle(toId) {
      const currentSection = this.$store.getters['section']
      if (currentSection > toId) {
        if (process.env.NODE_ENV === 'production') {
            this.pushDataLayer({
            from: currentSection,
            to: toId,
            event: 'stepBack'
          })
        }
        this.$parent.accordions.forEach((item) => {
          if (item.id === toId) {
            this.$store.commit('MOVE_SECTION', toId)
            item.active = true
          } else {
            item.active = false
          }
        })
      }
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition(el) {
      el.style.height = ''
    },
    accordionIsChecked(item) {
      return item.id < this.$store.getters['section']
    },
  },
}
</script>

<style lang="scss" scoped>
.accordion-item-trigger {
  padding: 0.75rem 1.25rem;
}

.accordion-item-title {
  position: relative;

  h4 {
    font-size: 1.25rem;
    margin-bottom: 0;
    padding-right: 1.25rem;
  }
}

.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
}

.accordion-item-trigger-icon {
  $size: 8px;
  display: block;
  position: absolute;
  top: 0;
  right: 1.25rem;
  bottom: 0;
  margin: auto;
  width: $size;
  height: $size;
  border-right: 2px solid #363636;
  border-bottom: 2px solid #363636;
  transform: translateY(calc(-1 * $size / 4)) rotate(45deg);
  transition: transform 0.2s ease;

  .is-active & {
    transform: translateY(calc($size / 4)) rotate(225deg);
  }
}

.accordion-item-details {
  overflow: hidden;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>
