import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

const app = createApp(App)
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://d15d1df5d93047e3aa4a3f99556f6eb5@o455520.ingest.sentry.io/5501009',
    integrations: [new VueIntegration({ Vue: app, attachProps: true }), new Integrations.BrowserTracing()],
  })
}
app.use(router)
app.use(store)
app.mount('#app')
