<template>
  <div class="navbar shadow bg-white py-5 px-4">
    <div class="navbar__wrapper col-12 sm:col-8 lg:col-6 xl:col-4 sm:offset-2 lg:offset-3 xl:offset-4 flex items-center">
      <img src="../assets/veke-logo.svg" alt="Veke" class="navbar__wrapper__logo cursor-pointer mr-4" @click="goToHome" />
      <h1 class="text-24 sm:text-32 font-bold">Lahjakortti</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    goToHome() {
      if (this.$route.name !== 'Home') this.$router.push({ name: 'Home' })
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  &__wrapper {
    max-width: 1200px;
    &__logo {
      max-width: 100px;
    }
  }
}
</style>
