<template>
  <div id="app">
    <navbar v-if="$route.name !== 'GiftcardPDF'" />
    <router-view :class="{ 'site-content': $route.name !== 'download-giftcard' }" />
    <veke-footer v-if="$route.name !== 'GiftcardPDF'" class="footer-container" />
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import VekeFooter from '@/components/footer/VekeFooter'

export default {
  name: 'App',
  components: { Navbar, VekeFooter },
  async created() {
    this.$store.dispatch('initPaymentButtonKey')
    await this.$store.dispatch('initContentfulData')
  },
}
</script>

<style lang="scss">
@import './assets/scss/main';
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.site-content {
  flex: 1;
}
</style>
