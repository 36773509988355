<script setup>
import { reactive, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()
const store = useStore()
import PaymentProviders from '@/components/checkout/PaymentProviders'
import LoadingSpinner from '@/components/LoadingSpinner'
import GlobalNotification from '@/components/notifications/GlobalNotification.vue'
const userDetails = reactive({
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  postcode: '',
  phone: '',
  email: '',
})
const payment = ref(null)
const notificationMessage = ref(null)
const success = ref(true)
const paymentLoading = ref(true)
const loading = ref(false)
const childComponentName = ref('')
onMounted(() => {
  childComponentName.value = payment.value.name
  paymentLoading.value = payment.value.loading
  if (route.query.error) {
    let query = Object.assign({}, route.query)
    delete query.error
    router.replace({ query })
    success.value = false
    notificationMessage.value = 'Virhe maksaessa'
  }
  if (route.query.cancel) {
    success.value = false
    let query = Object.assign({}, route.query)
    delete query.cancel
    router.replace({ query })
    success.value = false
    notificationMessage.value = 'Peruutit maksamisen'
  }
  setTimeout(() => ((notificationMessage.value = null), (success.value = false)), 4000)
})
const payOrder = () => {
  loading.value = true
  const cart = {
    uid: null,
    email: userDetails.email,
    domain: 'lahjakortti',
    user: {
      firstname: userDetails.firstname,
      lastname: userDetails.lastname,
      phone: userDetails.phone,
      email: userDetails.email,
    },
    items: [
      {
        sku: 'VLK-WEB',
        parentSku: '',
        title: 'Veke - Lahjakortti',
        variationName: '',
        type: 'buy-giftcard',
        amount: 1,
        price: {
          original: store.getters.cardAmount,
          final: store.getters.cardAmount,
        },
      },
    ],
    merchantData: {
      amount: store.getters.cardAmount,
      cardDetails: store.getters.cardDetails,
    },
    selectedPaymentMethod: payment.value.selectedPaymentMethod,
    selectedPaymentProvider: payment.value.selectedPaymentProvider,
    selectedShippingMethod: {
      group: 'Digital',
      id: 'SP',
      label: 'Sähköposti',
      price: 0,
    },
    addresses: {
      billing: {
        firstname: userDetails.firstname,
        lastname: userDetails.lastname,
        street1: userDetails.address,
        city: userDetails.city,
        postcode: userDetails.postcode,
        phone: userDetails.phone,
        countryCode: 'FI',
      },
      shipping: {
        firstname: userDetails.firstname,
        lastname: userDetails.lastname,
        street1: userDetails.address,
        city: userDetails.city,
        postcode: userDetails.postcode,
        phone: userDetails.phone,
        countryCode: 'FI',
      },
    },
  }
  axios
    .post(process.env.VUE_APP_FUNCTIONS_DOMAIN + '/veke3000-paymentGateway-payOrderV2', { cart })
    .then((response) => {
      if (response.data.response.redirectUrl) {
        window.location.href = response.data.response.redirectUrl
      }
    })
    .catch((error) => {
      console.error(error)
      success.value = false
      notificationMessage.value = 'Virhe maksaessa'
      loading.value = false
      setTimeout(() => ((notificationMessage.value = null), (success.value = false)), 4000)
    })
}
</script>

<template>
  <section class="container relative">
    <GlobalNotification v-if="notificationMessage" :message="notificationMessage" :success="success" />
    <div class="flex flex-wrap mt-10">
      <div class="w-full md:w-1/2 md:pr-4">
        <div class="border-b-2 border-veke-grey-dark">
          <h3>Tuotteet</h3>
        </div>
        <div class="flex justify-between mt-2">
          <p>Lahjakortti - {{ store.getters.cardTitle }}</p>
          <div>
            <p class="m-0">Summa: {{ store.getters.cardAmount }} €</p>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 md:pl-4 mt-4 md:mt-0">
        <div class="border-b-2 border-veke-grey-dark">
          <h3>Maksutiedot</h3>
        </div>
        <div>
          <div class="mt-2">
            <label for="email">Sähköpostiosoite</label>
            <input type="text" id="email" v-model="userDetails.email" class="block px-3 py-2 rounded border border-1 border-veke-grey shadow w-full" />
          </div>
          <div class="mt-2">
            <label for="firstname">Etunimi</label>
            <input type="text" id="firstname" v-model="userDetails.firstname" class="block px-3 py-2 rounded border border-1 border-veke-grey shadow w-full" />
          </div>
          <div class="mt-2">
            <label for="lastname">Sukunimi</label>
            <input type="text" id="lastname" v-model="userDetails.lastname" class="block px-3 py-2 rounded border border-1 border-veke-grey shadow w-full" />
          </div>
          <div class="mt-2">
            <label for="address">Katuosoite</label>
            <input type="text" id="address" v-model="userDetails.address" class="block px-3 py-2 rounded border border-1 border-veke-grey shadow w-full" />
          </div>
          <div class="mt-2 flex w-full">
            <div class="w-1/3 pr-2">
              <label for="postcode">Postinumero</label>
              <input type="text" id="postcode" v-model="userDetails.postcode" class="block px-3 py-2 rounded border border-1 border-veke-grey shadow w-full" />
            </div>
            <div class="w-2/3 pl-2">
              <label for="city">Postitoimipaikka</label>
              <input type="text" id="city" v-model="userDetails.city" class="block px-3 py-2 rounded border border-1 border-veke-grey shadow w-full" />
            </div>
          </div>
          <div class="mt-2">
            <label for="phone">Puhelinnumero</label>
            <input type="text" id="phone" v-model="userDetails.phone" class="block px-3 py-2 rounded border border-1 border-veke-grey shadow w-full" />
          </div>
        </div>
        <div class="mt-4">
          <h5>Valitse maksutapa</h5>
          <div>
            <payment-providers ref="payment" @loaded="paymentLoading = false" />
            <loading-spinner v-if="paymentLoading" />
            <button v-if="!paymentLoading" class="btn btn-veke mt-2 w-full" data-testid="btn-title" @click="payOrder()">
              <span v-if="!loading">Siirry maksamaan</span>
              <loading-spinner v-else class="flex justify-center" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
</style>
