<template>
  <div class="p-2 sm:p-5">
    <p class="px-3 mb-4">{{ info }}</p>
    <div class="flex flex-wrap justify-center">
      <theme class="w-50 test" v-for="theme in themes" :key="theme.id" :theme="theme" />
    </div>
  </div>
</template>

<script>
import giftcardSubComponent from '@/mixins/giftcardSubComponent'
import Theme from '@/components/giftcard/Theme'
import { mapGetters } from 'vuex'
export default {
  name: 'Themes',
  mixins: [giftcardSubComponent],
  components: { Theme },
  computed: {
    ...mapGetters({
      themes: 'activeThemes',
    }),
  },
}
</script>

<style lang="scss" scoped>
.test {
  border: 10px solid transparent;
}
</style>
