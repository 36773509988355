<template>
  <p class="text-grey-darkest">{{ infoText }}</p>
</template>

<script>
export default {
  name: 'InfoText',
  props: {
    infoText: {
      type: String,
      default: '',
    },
  },
}
</script>
